import {ref , onUnmounted} from 'vue';
export function codeBtn(time = 60){
  let timer = null
  const str = ref('获取验证码')
  const isOK = ref(true)
  //倒计时time秒
  const getCode = () => {
    if(timer) return
    let count = time
    count--
    str.value ='重新获取'+ count + 's'
    isOK.value = false
    timer = setInterval(() => {
      count--
      str.value = '重新获取'+ count + 's'
      if (count <= 0) {
        clearInterval(timer)
        timer = null
        str.value = '重新获取验证码'
        isOK.value = true
      }
    }, 1000)
  }
  onUnmounted(() => {
    str.value = '获取验证码'
    if(timer) {
      clearInterval(timer)
      timer = null
    }
    isOK.value = true
  })
  return {
    str,
    getCode,
    isOK
  }
}