import request from '../http'
import {managerUrl} from '../api'

//退出登录
export function loginOut(data){
  return request({
    url:managerUrl + "/carton/passport/login/out",
    method:'post',
    data
  })
}

// 登录
export function loginIn(data){
  return request({
    url:managerUrl + "/carton/passport/login/pwd",
    method:'post',
    data
  })
}

//  第三方登录
export function loginInThird(data){
  return request({
    url:managerUrl + "/carton/passport/login/teleinfo/code",
    method:'post',
    data
  })
}


//验证码登录
export function loginInCode(data) {
    return request({
        url: managerUrl + "/carton/passport/login/code",
        method: 'post',
        data
    })
}

//用户信息接口
export function baseUserInfo(data) {
    return request({
        url: managerUrl + "/carton/user/info/base",
        method: 'post',
        data
    })
}