import request from '../../http'
import {managerUrl} from '../../api'

//测试接口
export function test(data){
  return request({
    url:managerUrl + "/test",
    method:'post',
    data
  })
}

//用户基本信息
export function userBase(data){
  return request({
    url:managerUrl + "/carton/user/info/base",
    method:'post',
    data
  })
}

//绑定手机号
export function bindPhone(data){
  return request({
    url:managerUrl + "/carton/user/info/bind/phone",
    method:'post',
    data
  })
}

//解绑手机号
export function unbindPhone(data){
  return request({
    url:managerUrl + "/carton/user/info/un/bind/phone",
    method:'post',
    data
  })
}

//修改密码
export function updatePwd(data){
  return request({
    url:managerUrl + "/carton/passport/login/edit/pwd",
    method:'post',
    data
  })
}

//根据手机号验证修改密码
//placeId:4 登录 5 注册
export function updatePwdByPhone(data){
  return request({
    url:managerUrl + "/carton/passport/login/edit/pwd/phone",
    method:'post',
    data
  })
}

//获取验证码
export function codeSend(data){
  return request({
    url:managerUrl + "/carton/system/sms/code/send",
    method:'post',
    data
  })
}

//验证原手机号
export function checkPhoneBefore(data){
  return request({
    url:managerUrl + "/carton/user/info/check/phone",
    method:'post',
    data
  })
}

//重置密码
export  function resetPwd(data) {
  return request({
        url:managerUrl + "/carton/passport/login/reset/pwd",
        method:'post',
        data
      })
}

//注册第一步
export function registerStepOne(data) {
    return request({
        url: managerUrl + "/carton/passport/login/register/code",
        method: 'post',
        data
    })
}

//注册第二步
export function registerStepTwo(data) {
    return request({
        url: managerUrl + "/carton/passport/login/register/info",
        method: 'post',
        data
    })
}

//注销账号
export function logCancel(data) {
    return request({
        url: managerUrl + "/carton/passport/login/cancel",
        method: 'post',
        data
    })
}
