export const formRules = {
    //账号
    account: [
        {required: true, message: '请输入账号', trigger: 'blur'},
    ],
    phone: [
        {required: true, message: '请输入手机号', trigger: 'blur'},
        {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur'}
    ],
    code: [
        {required: true, message: '请输入验证码', trigger: 'blur'},
        {min: 6, message: '验证码是6位数字', trigger: 'blur'},
        //只能是数字
        {pattern: /^[0-9]*$/, message: '验证码只能是数字', trigger: 'blur'},
    ],
    pwd: [
        {required: true, message: '请输入密码', trigger: 'blur'},
        {min: 6, message: '密码最少6位', trigger: 'blur'},
        {max: 30, message: '密码最多12位', trigger: 'blur'}
    ],
    companyName: [
        {required: true, message: '请填写企业名称', trigger: 'blur'},
        //最少2位
        {min: 2, message: '企业名称最少2个字', trigger: 'blur'},
        //最多20位
        {max: 100, message: '企业名称最多100个字', trigger: 'blur'},
    ],
    contact: [
        {required: true, message: '请填写联系人', trigger: 'blur'},
        //最少2位
        {min: 1, message: '联系人最少1个字', trigger: 'blur'},
        //最多20位
        {max: 100, message: '联系人最多100个字', trigger: 'blur'},
    ],
    invitationCode: [
        //只能输入6位
        {min: 6, message: '请输入正确的邀请码', trigger: 'blur'},
        //最多20位
        {max: 6, message: '请输入正确的邀请码', trigger: 'blur'},
        //只能为数字和字母
        {pattern: /^[0-9a-zA-Z]*$/, message: '请输入正确的邀请码', trigger: 'blur'},
    ],
    newPwdCheck: [
        {required: true, message: '确认新密码', trigger: 'blur'},
        {min: 6, message: '密码最少6位', trigger: 'blur'},
        {max: 12, message: '密码最多12位', trigger: 'blur'},
    ],
    oldPwd: [
        {required: true, message: '请输入原密码', trigger: 'blur'},
        {min: 6, message: '密码最少6位', trigger: 'blur'},
        {max: 12, message: '密码最多12位', trigger: 'blur'},
    ],
    newPwd: [
        {required: true, message: '请输入新密码', trigger: 'blur'},
        {min: 6, message: '密码最少6位', trigger: 'blur'},
        {max: 12, message: '密码最多12位', trigger: 'blur'},
    ],
    nickName: [
        {required: true, message: '请输入联系人名称', trigger: 'blur'},
    ],
    address: [
        {required: true, message: '请填写地址', trigger: 'blur'},
    ],

}

//需要确认的路由
export const confirmRoute = [
    '/shop/order/buy',
    '/shop/order/renewal',
    '/shop/order/upLevel',
    '/shop/order/goodsList',
]
